<template>
  <div class="inner-container" :class="{ 'min-container': !getTabs }">
    <div class="page-header">
      <h1>{{ $t("CATVISIB") }} {{ $t("TypePrestation.Fourniture") }}</h1>
    </div>

    <div class="content" v-if="!getLoadingprestation">
      <div class="content-header">
        <searchBar
          :placeholder="$t('CATEGORY_SEARCH')"
          :searchFunc="allcategorieArticle"
          @returnValue="updateSearch($event)"
        ></searchBar>
      </div>
      <div class="filtre">
        <p class="table-count">
          {{ getTotalRowcategorieArticle }} {{ $t("CATEGORIES") }}
        </p>
      </div>
      <div class="table-rapport-style w-100">
        <table>
          <thead style="border-radius: 5px 0px 0px 0px">
            <tr>
              <th style="border-radius: 0px 5px 0px 0px">
                {{ $t("WORDING") }}
              </th>
              <th>
                {{ $t("CATMETIER") }}
              </th>
              <th>
                {{ $t("ACTIVER") }}
              </th>
            </tr>
          </thead>
          <tbody v-for="(data, i) in computedAllCategorie" :key="i">
            <tr role="row" class="ligneNormale">
              <td aria-colindex="1" role="cell">
                {{ data.lib }}
              </td>
              <td aria-colindex="2" role="cell" v-if="data.categorie_metier">
                <p v-for="(categorie, i) in data.categorie_metier" :key="i">
                  {{ categorie ? categorie.lib : "" }}
                </p>
              </td>
              <td v-else></td>
              <td aria-colindex="3" role="cell">
                <b-form-checkbox
                  v-model="data.active"
                  name="check-button"
                  switch
                  @change="active(data)"
                >
                </b-form-checkbox>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <b-pagination
        v-model="page"
        :total-rows="getTotalRowcategorieArticle"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        last-number
        first-number
        align="center"
        size="sm"
        @change="pagination"
        class=""
      ></b-pagination>
    </div>
    <div class="text-center center-spin" v-else>
      <b-spinner
        variant="primary"
        label="Spinning"
        class="big-spin"
      ></b-spinner>
    </div>
    <CommentMarche to="/how-it-work/categorie"></CommentMarche>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength } from "@vuelidate/validators";
import CommentMarche from "../components/commentMarche.vue";
import searchBar from "../components/ui/searchBar.vue";

export default {
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  components: {
    CommentMarche,
    searchBar,
  },
  data() {
    return {
      langueToConfig: null,
      langueToDelete: null,
      page: 1,
      perPage: 10,
      oldrefrence: null,
      errorD: false,
      errorU: false,
    };
  },
  validations() {
    return {
      langueToConfig: {
        lib: { required, maxLength: maxLength(50) },
        iso_code: { required },
      },
    };
  },
  methods: {
    ...mapActions([
      "visibilite_prestation",
      "allcategorieArticle",
      "store_visibilite_categorie_article",
      "store_visibilite_categorie_metier",
    ]),

    hideModal(ref) {
      this.$refs[ref].hide();
    },

    handleStore() {
      this.allcategorieArticle({
        page: 1,
        per_page: 10,
      }).then(() => {
        this.$root.$emit("bv::show::modal", "categorieVisibleModal");
      });
    },

    async active(item) {
      var list = this.getAllprestationV;
      if (item.active) {
        // Vérifier si l'objet existe déjà dans la liste en comparant les identifiants
        let objectExists = list.some((obj) => obj.id == item.id);

        // Si l'objet n'existe pas encore dans la liste, l'ajouter
        if (!objectExists) {
          list.push({ id: item.id });
          await this.store_visibilite_categorie_article({
            categorie_article_id: list.map((item) => item.id),
          }).then(() => {
            this.visibilite_prestation();
          });
        }
      } else {
        list = this.getAllprestationV.filter((obj) => obj.id != item.id);
        await this.store_visibilite_categorie_article({
          categorie_article_id: list.map((item) => item.id),
        }).then(() => {
          this.visibilite_prestation();
        });
      }
    },

    updateSearch(s) {
      this.page = 1;
      this.search = s;
    },

    pagination(paginate) {
      this.page = paginate;
      this.allcategorieArticle({
        page: this.page,
        per_page: this.perPage,
        search: this.search,
      });
    },

    setup(refresh = false) {
      if (refresh) this.page = 1;
      this.allcategorieArticle({
        page: this.page,
        per_page: this.perPage,
        search: this.search,
      });
      this.visibilite_prestation();
    },
  },

  mounted() {
    this.setup(true);
  },

  computed: {
    ...mapGetters([
      "getAllcategorieArticle",
      "getLoadingcategorieArticle",
      "getTabs",
      "getTotalRowcategorieArticle",
      "getAllprestationV",
      "getLoadingprestation",
    ]),

    computedAllCategorie() {
      var liste = this.getAllcategorieArticle;
      liste?.forEach((element) => {
        const objectFound = this.getAllprestationV.find(
          (item) => item.id == element.id
        );
        if (objectFound) {
          element.active = true;
        } else {
          element.active = false;
        }
      });
      return liste;
    },
  },
};
</script>

<style lang="scss" scoped>
.inner-container .page-header h1 {
  width: 100%;
}
.content {
  & p {
    font-size: 12px;
    font-weight: 500;
    padding: 3px 0;
    margin: 0;
  }
}

.w-100 {
  background-color: #f8f8f8;
  padding: 4px;
  overflow-x: auto;
}

body {
  background: #fafdff;
}

.actionModel {
  position: relative;
  padding: 15px 0 0;

  & .block-spinner {
    display: flex;
    justify-content: center;
  }

  & .spinner-border {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.filtre {
  display: flex;
  align-items: center;
}
</style>
